import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { Paper, Typography } from '@material-ui/core';
import DatePicker from 'components/modules/Shared/DatePicker';
import Button from 'components/modules/Shared/Button';

import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import { useStyles } from './styles';

const RenewalForm = ({
  submit,
  loading,
  error = null,
  validate,
  submitted,
  isDesktop,
  productCount,
  label,
  successMessage,
  type,
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const trackOnceChange = useTrackOnce();
  const trackOnceSubmit = useTrackOnce();
  const handleChangeDate = useCallback(
    (_event, date) => {
      validate(date);
      setDate(date);
      if (type === 'Home Insurance') {
        trackOnceChange(EVENTS.THANKYOU_HOME_INSURANCE_RENEWAL_DATE_CHANGED);
      }
    },
    [validate, setDate, trackOnceChange]
  );

  const handleSubmitDate = useCallback(
    (event) => {
      event.preventDefault();
      submit(date);
      if (type === 'Home Insurance') {
        trackOnceSubmit(EVENTS.THANKYOU_HOME_INSURANCE_RENEWAL_DATE_SUBMITTED);
      }
    },
    [date, submit, trackOnceSubmit]
  );

  return (
    <Paper classes={{ root: clsx(classes.container) }}>
      {loading || error || !submitted ? (
        <form onSubmit={handleSubmitDate} className={classes.form}>
          <div
            className={clsx(classes.datePicker, {
              [classes.datePickerForMoreThanOneRenewal]: productCount <= 2,
            })}
          >
            <DatePicker
              label={label}
              onChange={handleChangeDate}
              value={date}
              placeholder="dd/mm/yyyy"
              error={!!error}
              errorMessage={error}
              disabled={loading}
              fullWidth={true}
            />
          </div>
          <div className={classes.actions}>
            <Button
              type="submit"
              variant="outlined"
              disabled={!date || loading || error}
              size="large"
              loading={loading}
              fullWidth={!isDesktop}
            >
              Submit
            </Button>
          </div>
        </form>
      ) : (
        <Typography className={classes.submitted}>{successMessage}</Typography>
      )}
    </Paper>
  );
};

RenewalForm.propTypes = {
  submit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isDesktop: PropTypes.bool,
  productCount: PropTypes.number,
  label: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

export default RenewalForm;
