import { connector } from 'redux/utils/connector';
import { submit, validate } from 'redux/modules/MobileRenewal/actions';
import { STORE_NAME as MobileRenewalStore } from 'redux/modules/MobileRenewal/constants';
import RenewalForm from '../RenewalForm';

const mapStateToProps = () => {};

const boundActions = {
  submit,
  validate,
};
export default connector(
  MobileRenewalStore,
  mapStateToProps,
  boundActions
)(RenewalForm);
