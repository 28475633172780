import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import { Deprecated } from 'app/lib/propTypes';
import styles from './styles';

const TitleBar = ({
  children,
  variant = 'h2',
  customStyles,
  iconRight,
  onClick,
  testId,
  dataTestId = '',
  classNames = {},
  classes,
  complete,
  titleCenter,
  iconRightStyles,
}) => (
  <div
    data-test-id={dataTestId || testId}
    onClick={onClick}
    className={classnames(
      classes.base,
      onClick ? classes.clickable : '',
      classNames.root
    )}
    style={get(customStyles, 'root')}
  >
    {complete && (
      <FlagPointIcon
        className={classnames(classes.iconLeft, classNames.iconLeft)}
      />
    )}
    <Typography
      variant={variant}
      className={classnames(
        classes.title,
        titleCenter ? classes.titleCenter : '',
        classNames.title
      )}
      style={get(customStyles, 'title')}
    >
      {children}
    </Typography>
    {iconRight && (
      <div className={classNames.iconRight} style={iconRightStyles}>
        {typeof iconRight === 'function' ? iconRight() : iconRight}
      </div>
    )}
  </div>
);

TitleBar.propTypes = {
  iconRight: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  complete: PropTypes.bool,
  titleCenter: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.any,
  classNames: PropTypes.shape({
    root: PropTypes.string,
    title: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
  iconRightStyles: Deprecated(PropTypes.object),
  testId: Deprecated(PropTypes.string),
  customStyles: Deprecated(
    PropTypes.shape({
      root: PropTypes.object,
      title: PropTypes.object,
    })
  ),
  click: Deprecated(PropTypes.string),
  colorStyle: Deprecated(
    PropTypes.oneOf([
      'blue',
      'green',
      'magenta',
      'orange',
      'pink',
      'purple',
      '',
    ])
  ),
  titleColor: Deprecated(PropTypes.string),
  completeArrow: Deprecated(PropTypes.bool),
};

export default withStyles(styles)(TitleBar);
