import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import SectionCentered from 'modules/layout/SectionCentered';
import useStyles from './styles';

const ThankYouHeader = ({ name }) => {
  const classes = useStyles();

  return (
    <header className={classes.header} id="page-header">
      <SectionCentered
        newLayout
        sectionCenteredClasses={{
          root: classes.headerWrapper,
          container: classes.headerDecoration,
        }}
      >
        <Typography variant="h1" className={classes.title}>
          Welcome <span className={classes.titleHighlight}>{name}!</span>
        </Typography>
      </SectionCentered>
    </header>
  );
};

ThankYouHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ThankYouHeader;
