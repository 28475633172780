import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2, 0),
  },
  headerWrapper: {
    backgroundColor: 'transparent',
  },
  headerDecoration: {
    maxWidth: 856,
    '&::before, &::after': {
      content: "''",
      display: 'block',
      height: 30,
      background: 'url("images/thankyou/confetti.svg")',
      backgroundRepeat: 'repeat-x',
      backgroundPositionY: 'top',
    },
    '&::after': {
      backgroundPositionX: -315,
    },
  },
  title: {
    margin: theme.spacing(2, 0),
    fontSize: 32,
    [theme.breakpoints.up('sm')]: {
      fontSize: 42,
    },
  },
  titleHighlight: {
    color: theme.palette.services.broadband,
  },
  accountNumber: {
    marginBottom: theme.spacing(2),
    '& strong': {
      userSelect: 'all',
    },
  },
  link: {
    color: colors.cyan600,
    fontWeight: theme.typography.fontWeightMedium,
  },

  subTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 28,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
  },

  section: {
    maxWidth: 856,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  nextSectionWrapper: {
    backgroundColor: theme.palette.common.white,
  },

  nextList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  nextListItem: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: theme.spacing(1),
      color: theme.palette.success.main,
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  referralBanners: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
});

export default makeStyles(style);
