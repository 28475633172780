import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon } from '@utilitywarehouse/colour-system';
import useStyles from '../styles';
import Button from 'components/modules/Shared/Button';
import {
  useTracking,
  useTrackOnce,
} from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const useLocalStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  copy: {
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  copyValue: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      alignItems: 'center',
      height: 48,
      width: 240,
      padding: theme.spacing(0, 3),
      border: `2px dashed ${colorsCommon.brandPink}`,
      borderRadius: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& > span': {
      overflow: 'hidden',
      userSelect: 'all',
      whiteSpace: 'nowrap',
    },
  },
  tooltip: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const PartnerReferAFriend = ({ partnerName, setLeadCaptureDialog }) => {
  const classes = {
    ...useStyles(),
    ...useLocalStyles(),
  };

  const trackOnce = useTrackOnce();
  const tracking = useTracking();

  useEffect(() => {
    trackOnce(EVENTS.RAF_LEAD_CAPTURE_CTA_DISPLAYED);
  }, []);

  return (
    <Paper classes={{ root: clsx(classes.banner, classes.bannerLight) }}>
      <div className={classes.content}>
        <div>
          <Typography variant="h3" component="h3" className={classes.title}>
            <>
              <span className={classes.highlight}>£50 credit</span> when you
              refer a friend
            </>
          </Typography>
          <Typography>
            Simply share your friend's contact details with{' '}
            {partnerName || 'your Partner'}. If they're a homeowner and switch
            two or more services, you'll both get £50 in credit.
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            onClick={() => {
              setLeadCaptureDialog(true);
              tracking.track(EVENTS.RAF_LEAD_CAPTURE_CTA_CLICKED);
            }}
            classes={{
              root: classes.button,
            }}
            variant="contained"
            size="large"
            target="_blank"
            rel="noopener noreferrer"
          >
            Share my friend's details
          </Button>
        </div>
      </div>
    </Paper>
  );
};

PartnerReferAFriend.propTypes = {
  partnerName: PropTypes.string.isRequired,
  setLeadCaptureDialog: PropTypes.func.isRequired,
};

export default PartnerReferAFriend;
