import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    minHeight: 110,
  },
  form: {
    // bad fix for the shared `CustomerUI/TextField` component
    // having a hard-set top margin of 32px, without any means to override
    // it in the date picker component.
    marginTop: theme.spacing(-4),
    display: 'flex',
    padding: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  actions: {
    marginTop: 65,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 10,
    },
  },
  datePicker: {
    minWidth: 280,
    maxWidth: 280,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  datePickerForMoreThanOneRenewal: {
    width: '100%',
    minWidth: 280,
    maxWidth: 321,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  submitted: {
    color: theme.palette.success.light,
    padding: theme.spacing(1, 0, 1, 0),
  },
}));
