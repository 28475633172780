import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './style';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';

const TogglePanel = ({ children, title, icon, onChange }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel onChange={onChange}>
      <ExpansionPanelSummary>
        {icon &&
          React.cloneElement(icon, {
            ...icon.props,
            className: classNames(icon.props.className, classes.icon),
          })}
        <Typography variant="h4" component="h2" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.content}>{children}</div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

TogglePanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onChange: PropTypes.onChange,
};

export default TogglePanel;
