import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import useStyles from '../styles';
import { Paper, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeInsurance from './RenewalForms/HomeInsuranceRenewal';
import MobileRenewal from './RenewalForms/MobileRenewal';
import BroadbandRenewal from './RenewalForms/BroadbandRenewal';
import {
  renewalBannerInfo,
  broadbandProps,
  mobileProps,
  homeInsuranceProps,
} from './renewalFormObjects.js';

const useLocalStyles = makeStyles(() => ({
  graphic: {
    '& img': {
      maxWidth: 343,
    },
  },
  banner: {
    columnGap: 0,
  },
}));

const Renewals = ({ products }) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [content, setContent] = useState(null);
  const [productCount, setProductCount] = useState(0);
  const productsAssigned = {
    energy: !!products.energy,
    broadband: !!products.broadband,
    mobile: !!products.mobile,
    homeInsurance: !!products.insurance,
  };

  useEffect(() => {
    const productCount = Object.values(productsAssigned).filter(Boolean).length;
    setProductCount(productCount);
    if (productCount <= 2) {
      setContent(renewalBannerInfo.moreThanOneServiceTaken);
    } else if (!productsAssigned.homeInsurance) {
      setContent(renewalBannerInfo.homeInsuranceNotTaken);
    } else if (!productsAssigned.mobile) {
      setContent(renewalBannerInfo.mobileNotTaken);
    } else if (!productsAssigned.broadband) {
      setContent(renewalBannerInfo.broadbandNotTaken);
    }
  }, [productsAssigned]);

  if (!content) {
    return null;
  }

  const {
    firstSectionTitle,
    highlightedTitleOfSection,
    endOfTitleSection,
    firstParagraphSection,
    boldParagraphSection,
    endParagraphSection,
    paragraphSectionExplanation,
    termsAndConditions,
    image,
  } = content;

  return (
    <Paper
      classes={{
        root: clsx(classes.banner, productCount > 2 && localClasses.banner),
      }}
    >
      <div className={classes.content}>
        <Typography
          variant="h3"
          className={clsx(classes.title)}
          color="primary"
        >
          {firstSectionTitle}{' '}
          <span className={classes.highlight}>{highlightedTitleOfSection}</span>{' '}
          {endOfTitleSection}
        </Typography>
        <Typography className={clsx(classes.firstParagraphSection)}>
          {firstParagraphSection}{' '}
          <span className={classes.homeinsuranceBold}>
            {boldParagraphSection}
          </span>{' '}
          {endParagraphSection}
        </Typography>
        <Typography>{paragraphSectionExplanation}</Typography>
        <div className={classes.formRenewalSection}>
          {!productsAssigned.broadband && (
            <BroadbandRenewal
              {...broadbandProps}
              isDesktop={isDesktop}
              productCount={productCount}
            />
          )}
          {!productsAssigned.mobile && (
            <MobileRenewal
              {...mobileProps}
              isDesktop={isDesktop}
              productCount={productCount}
            />
          )}
          {!productsAssigned.homeInsurance && (
            <HomeInsurance
              {...homeInsuranceProps}
              isDesktop={isDesktop}
              productCount={productCount}
            />
          )}
        </div>
        <Typography className={classes.termsAndConditions}>
          {termsAndConditions}
        </Typography>
      </div>
      <div className={clsx(classes.graphicWrapper)}>
        <picture
          className={clsx(
            classes.graphic,
            productCount <= 2 && localClasses.graphic
          )}
        >
          <source media="(min-resolution: 150dpi)" srcSet={image} />
          <img src={image} alt="" />
        </picture>
      </div>
    </Paper>
  );
};

Renewals.propTypes = {
  products: PropTypes.object.isRequired,
};

export default Renewals;
