import React from 'react';
import { string } from 'prop-types';
import clsx from 'classnames';
import {
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/modules/Shared/Button';
import useStyles from '../styles';

const useLocalStyles = makeStyles((theme) => ({
  banner: {
    flexDirection: 'column-reverse',
    marginTop: theme.spacing(4.5),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  title: {
    marginBottom: 0,
  },
  graphicWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      display: 'block',
    },
  },
  graphic: {
    '& img': {
      display: 'inline',
    },
  },
  styledLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
}));

const SmartMeter = ({ smartLink }) => {
  const classes = useStyles();
  const theme = useTheme();
  const localClasses = useLocalStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Paper classes={{ root: clsx(classes.banner, localClasses.banner) }}>
      <div className={localClasses.content}>
        <Typography
          variant="h3"
          className={clsx(classes.title, localClasses.title)}
          color="primary"
        >
          Upgrade to a smart meter for free
        </Typography>
        <Typography>
          Don’t have a smart meter yet? Book your free upgrade today
        </Typography>
        <div>
          <Button
            variant="outlined"
            size="large"
            fullWidth={!isDesktop}
            className={localClasses.styledLink}
            href={smartLink}
            target="_blank"
            rel="noopener noreferrer"
            component={Link}
          >
            Book appointment
          </Button>
        </div>
      </div>
      <div
        className={clsx(classes.graphicWrapper, localClasses.graphicWrapper)}
      >
        <picture className={clsx(classes.graphic, localClasses.graphic)}>
          <source
            media="(min-resolution: 150dpi)"
            srcSet="images/thankyou/tySmartMeter2x.png"
          />
          <img
            src="images/thankyou/tySmartMeter.png"
            alt="Smart meter on the table"
          />
        </picture>
      </div>
    </Paper>
  );
};

SmartMeter.propTypes = {
  smartLink: string.isRequired,
};

export default SmartMeter;
