import { getPrimaryPaletteColor } from 'app/lib/theme';

export default (theme) => {
  const primaryPaletteColor = getPrimaryPaletteColor('main')(theme);

  return {
    title: {
      color: primaryPaletteColor,
    },
  };
};
