import { getBreakpoints, getSpacing } from 'app/lib/theme';

export default (theme) => {
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);

  return {
    alert: {
      margin: spacing(4, 0),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        maxWidth: '75%',
      },
    },
  };
};
