import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { useTheme, useMediaQuery, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon, colorsDark } from '@utilitywarehouse/colour-system';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import CopyButton from 'components/modules/Shared/CopyButton';
import useStyles from '../styles';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';

const useLocalStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  copy: {
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  copyValue: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      alignItems: 'center',
      height: 48,
      width: 240,
      padding: theme.spacing(0, 3),
      border: `2px dashed ${colorsCommon.brandPink}`,
      borderRadius: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& > span': {
      overflow: 'hidden',
      userSelect: 'all',
      whiteSpace: 'nowrap',
    },
  },
  graphicRaf: {
    '& img': {
      maxWidth: 180,
    },
  },
  copyButton: {
    transition: 'background-color 250ms ease-out',
    flexShrink: 0,
  },
  copyButtonLabel: {
    textTransform: 'none',
  },
  copyButtonSuccess: {
    backgroundColor: colorsDark.green500,
  },
}));

const ReferAFriend = ({ customerAccountNumber }) => {
  const classes = {
    ...useStyles(),
    ...useLocalStyles(),
  };
  const theme = useTheme();
  const ref = React.useRef();
  const trackOnceDisplayed = useTrackOnce();
  const trackOnceClicked = useTrackOnce();
  const [copied, setCopied] = useState(false);
  const referralLink = `${window.location.origin}/refer/${customerAccountNumber}?source=jtc_thankyou`;

  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, [setCopied]);

  useScrollIntoView(
    ref,
    ({ visible }) => {
      if (visible)
        trackOnceDisplayed(EVENTS.THANKYOU_RAF_COPY_LINK_CTA_DISPLAYED);
    },
    { threshold: 0.8 }
  );

  return (
    <Paper
      classes={{ root: clsx(classes.banner, classes.bannerDark) }}
      data-test-id="ThankYouReferAFriend"
    >
      <div className={classes.content}>
        <div>
          <Typography variant="h3" component="h3" className={classes.title}>
            <>
              Refer friends,{' '}
              <span className={classes.highlight}>save more</span>{' '}
            </>
          </Typography>
          <Typography>
            For each homeowner friend that switches two or more services, we’ll
            give you <b className={classes.highlight}>£50</b> off your bill and
            up to <b className={classes.highlight}>£50</b> off theirs. It’s a
            win-win.
          </Typography>
        </div>
        <div className={classes.actions}>
          <div ref={ref} className={classes.copy}>
            <Typography component="div" className={classes.copyValue}>
              <span title={referralLink}>{referralLink}</span>
            </Typography>
            <CopyButton
              value={referralLink}
              label={copied ? 'Copied!' : 'Copy my link'}
              onCopy={() => {
                trackOnceClicked(EVENTS.THANKYOU_RAF_COPY_LINK_CTA_CLICKED);
                handleCopy();
              }}
              buttonProps={{
                fullWidth: !useMediaQuery(theme.breakpoints.up('sm')),
              }}
              buttonClasses={{
                root: clsx(classes.copyButton, {
                  [classes.copyButtonSuccess]: copied,
                }),
                label: classes.copyButtonLabel,
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.graphicWrapper}>
        <picture className={clsx(classes.graphic, classes.graphicRaf)}>
          <source
            media="(min-resolution: 150dpi)"
            srcSet="images/thankyou/tyRaf2x.png"
          />
          <img src="images/thankyou/tyRaf.png" alt="" />
        </picture>
      </div>
    </Paper>
  );
};

ReferAFriend.propTypes = {
  customerAccountNumber: PropTypes.string.isRequired,
};

export default ReferAFriend;
