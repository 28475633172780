import { connect } from 'react-redux';
import { fetchRegistrationToken } from 'redux/modules/App/actions';
import { ApplicationApi } from 'redux/modules/App/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { REQUEST_FETCHING } from 'constants/requestStatus';
import PartnerSignUp from './PartnerSignUp';

const mapStateToProps = (state) => {
  const registrationToken = ApplicationApi.getRegistrationToken(state);
  const registrationTokenLoading =
    ApplicationApi.getRequestStatus(state, 'registrationToken') ===
    REQUEST_FETCHING;

  return {
    partnerName: PartnerLoginApi.getPartnerName(state),
    registrationToken,
    registrationTokenLoading,
  };
};

const boundActions = {
  fetchRegistrationToken,
};
export default connect(mapStateToProps, boundActions)(PartnerSignUp);
