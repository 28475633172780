import { connector } from 'redux/utils/connector';
import { submit, validate } from 'redux/modules/HomeInsuranceRenewal/actions';
import { STORE_NAME as HomeInsuranceStore } from 'redux/modules/HomeInsuranceRenewal/constants';
import RenewalForm from '../RenewalForm.js';

const mapStateToProps = () => {};

const boundActions = {
  submit,
  validate,
};

export default connector(
  HomeInsuranceStore,
  mapStateToProps,
  boundActions
)(RenewalForm);
