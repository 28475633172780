import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

export const VideoOverlay = ({
  classes,
  label,
  open,
  toggleVideo,
  videoId,
}) => (
  <Dialog
    contentLabel={label}
    fullWidth={true}
    maxWidth="md"
    onClose={toggleVideo}
    open={open}
    style={styles}
  >
    <DialogContent>
      <DialogContentText>
        <div className={classes.container}>
          <div className={classes.player}>
            <iframe
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              height="100%"
              src={`//player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&color=440259&autoplay=0`}
              title={label}
              width="100%"
            />
          </div>
        </div>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

VideoOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleVideo: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
};

VideoOverlay.defaultProps = {
  open: false,
};

export default withStyles(styles)(VideoOverlay);
