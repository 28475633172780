import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon, colors } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  banner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: theme.spacing(2),
    padding: theme.spacing(2, 3),
  },
  bannerDark: {
    backgroundColor: theme.palette.text.primary,
    color: colorsCommon.brandWhite,
  },
  bannerLight: {
    backgroundColor: theme.palette.grey['200'],
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
  },
  highlight: {
    color: colorsCommon.serviceInsurance,
    '$bannerDark &': {
      color: colorsCommon.brandPink,
    },
    '$bannerLight &': {
      color: colors.pink500,
    },
  },
  '@global': {
    strong: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    b: {
      '&$highlight': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  graphicWrapper: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  graphic: {
    '& img': {
      maxWidth: 255,
      height: 'auto',
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'unset',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  termsAndConditions: {
    marginTop: theme.spacing(-2),
    fontSize: 11,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      display: 'block',
    },
  },
  homeinsuranceBold: {
    fontWeight: 900,
  },
  submitted: {
    color: theme.palette.success.light,
  },
  button: {
    backgroundColor: theme.palette.grey['200'],
    border: `2px solid ${colors.cyan400}`,
  },
  themeGap: {
    marginTop: theme.spacing(2),
  },
  firstParagraphSection: {
    marginBottom: theme.spacing(2),
  },
  formRenewalSection: {
    marginTop: theme.spacing(2),
  },
});

export default makeStyles(style);
