import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { EVENTS } from 'app/lib/analytics/constants';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import useOptlyEvent from 'app/lib/analytics/Optimizely/useOptlyEvent';
import { events as optlyEvents } from 'app/lib/analytics/Optimizely/features';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';

import styles from './styles';
import { MEMBERSHIP_NUMBER } from './copy';

const ThankYouPresentation = ({ accountNumber, classes }) => {
  const appDuration = useSelector(OrderSummaryApi.getApplicationDuration);
  const trackOnce = useTrackOnce();
  const coreServicesState = useEvents(EVENTS.APPLICATION_COMPLETED);
  const optlyEvent = useOptlyEvent();
  useEffect(() => {
    trackOnce(EVENTS.APPLICATION_COMPLETED, coreServicesState);
    optlyEvent(optlyEvents.APPLICATION_COMPLETED, {
      value:
        appDuration <= 24 * 60 * 60 && appDuration > 0
          ? appDuration
          : undefined,
    });
  }, [appDuration, optlyEvent, coreServicesState, trackOnce]);

  return (
    <Typography className={classes.title} component="h2" paragraph variant="h2">
      {MEMBERSHIP_NUMBER(accountNumber)}
    </Typography>
  );
};

ThankYouPresentation.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThankYouPresentation);
