export default (theme) => ({
  label: {
    color: theme.palette.grey[500],
    '&$focused': {
      color: theme.palette.primary.main,
    },
  },
  input: {
    color: theme.palette.text.primary,
  },
  focused: {
    color: theme.palette.primary.main,
  },
});
