import { connector } from 'redux/utils/connector';
import { submit, validate } from 'redux/modules/BroadbandRenewal/actions';
import { STORE_NAME as BroadbandRenewalStore } from 'redux/modules/BroadbandRenewal/constants';
import RenewalForm from '../RenewalForm';

const mapStateToProps = () => {};

const boundActions = {
  submit,
  validate,
};

export default connector(
  BroadbandRenewalStore,
  mapStateToProps,
  boundActions
)(RenewalForm);
