export const renewalBannerInfo = {
  broadbandNotTaken: {
    firstSectionTitle: 'Bigger savings with our',
    highlightedTitleOfSection: 'Broadband deals',
    endOfTitleSection: '',
    firstParagraphSection:
      'Get the best broadband deals with no mid-contract price rises - guaranteed.',
    boldParagraphSection: '',
    endParagraphSection: 'on average, compared to their previous policy.',
    paragraphSectionExplanation:
      "Let us know when your broadband contracts ends, and we'll be in touch with a money-saving reminder close to the time. An estimate will be fine if you're unsure of the exact date.",
    termsAndConditions:
      "*Ultra Broadband vs major national competitors' (BT, Virgin, EE, TalkTalk, Sky) closest equivalent 40 mbps broadband on 18 month minimum term including inflationary price rises. New customers only. Eligibility and terms apply. Correct as 01.04.2024. To verify: contact us to verify.",
    image: 'images/thankyou/tyBroadbandRenewal.png',
  },
  homeInsuranceNotTaken: {
    firstSectionTitle: 'Provide your home insurance renewal date',
    highlightedTitleOfSection: '',
    endOfTitleSection: '',
    firstParagraphSection: '',
    boldParagraphSection: '',
    endParagraphSection: '',
    paragraphSectionExplanation:
      "Let us know when your home insurance policy expires and we'll be in touch to let you know what we can offer you.",
    termsAndConditions: '',
    image: 'images/thankyou/tyHomeInsurance.png',
  },
  mobileNotTaken: {
    firstSectionTitle: 'Bigger savings with our',
    highlightedTitleOfSection: 'Mobile deals',
    endOfTitleSection: '',
    firstParagraphSection:
      'Discover affordable mobile phone deals and SIM-only contracts with UW. Enjoy flexible monthly contracts, fixed prices until 2025 and all your services on one simple bill.',
    boldParagraphSection: '',
    endParagraphSection: '',
    paragraphSectionExplanation:
      "Let us know when your current mobile contracts ends, and we'll be in touch with a money-saving reminder closer to the time. An estimate will be fine if you're unsure of the exact date.",
    termsAndConditions: '',
    image: 'images/thankyou/tyMobileRenewal.png',
  },
  moreThanOneServiceTaken: {
    firstSectionTitle: 'UW customers save over',
    highlightedTitleOfSection: '£400',
    endOfTitleSection: 'when they switch all their services',
    firstParagraphSection: '',
    boldParagraphSection: '',
    endParagraphSection: '',
    paragraphSectionExplanation:
      "Let us know when your current contracts and policies expire and we'll follow up with a remind closer to the time.",
    termsAndConditions: '',
    image: 'images/landing/services.png',
  },
};

export const broadbandProps = {
  label: 'Broadband contract expiry date',
  successMessage:
    "Thank you for submitting your broadband contract renewal date. We'll send you an email around the time you're due to renew.",
  type: 'Broadband',
};

export const mobileProps = {
  label: 'Mobile SIM contract expiry date',
  successMessage:
    "Thank you for submitting your mobile contract renewal date. We'll send you an email around the time you're due to renew.",
  type: 'Mobile',
};

export const homeInsuranceProps = {
  label: 'Home insurance expiry date',
  successMessage:
    "Thank you for submitting your home insurance renewal date. We'll send you an email around the time you're due to renew.",
  type: 'Home Insurance',
};
