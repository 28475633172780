import { connect } from 'react-redux';
import { PartnerLoginApi } from 'app/redux/modules/PartnerLogin/api';
import PartnerReferAFriend from './PartnerReferAFriend';
import { setLeadCaptureDialog } from 'app/redux/modules/LeadCapture/actions';

const mapStateToProps = (state) => ({
  partnerName: PartnerLoginApi.getPartnerName(state),
});

export default connect(mapStateToProps, {
  setLeadCaptureDialog,
})(PartnerReferAFriend);
