import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import PageHeading from 'components/modules/Shared/PageHeading';
import SectionCentered from 'modules/layout/SectionCentered';
import ThankYouUI from 'components/modules/ThankYouPage/Presentation';
import MobileContractRenewalContainer from 'redux/modules/MobileContractRenewal/container';
import HomeInsuranceRenewalContainer from 'redux/modules/HomeInsuranceRenewal/container';
import styles from './styles';
import { ALERT, TITLE } from 'components/pages/ThankYou/copy';

export const ThankYouPage = ({ accountNumber, classes }) => {
  return (
    <div>
      <PageHeading title={TITLE} />
      <SectionCentered>
        <div className={classes.container}>
          <Alert
            id="ThankYouSuccessBanner"
            className={classes.alert}
            icon={<FlagPointIcon />}
            severity="success"
          >
            {ALERT}
          </Alert>
          <ThankYouUI accountNumber={accountNumber} />
          <HomeInsuranceRenewalContainer />
          <MobileContractRenewalContainer />
        </div>
      </SectionCentered>
    </div>
  );
};

ThankYouPage.propTypes = {
  accountNumber: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default withStyles(styles)(ThankYouPage);
