import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  content: {
    contentVisibility: 'auto',
    containIntrinsicSize: '300px',
    width: '100%',
  },
});

export default makeStyles(style);
