import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Paper, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import Button from 'components/modules/Shared/Button';
import useStyles from '../styles';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';

const PartnerSignUp = ({
  partnerName,
  registrationToken,
  registrationTokenLoading = false,
  fetchRegistrationToken,
}) => {
  const classes = useStyles();
  const ref = React.useRef();
  const trackOnceDisplayed = useTrackOnce();
  const trackOnceClicked = useTrackOnce();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    fetchRegistrationToken();
  }, [fetchRegistrationToken]);

  useScrollIntoView(
    ref,
    ({ visible }) => {
      if (visible)
        trackOnceDisplayed(EVENTS.THANKYOU_PARTNER_SIGNUP_CTA_DISPLAYED);
    },
    { threshold: 0.8 }
  );

  const handleSignup = () => {
    trackOnceClicked(EVENTS.THANKYOU_PARTNER_SIGNUP_CTA_CLICKED);
  };

  const { variant } = useFeature(features.ENABLE_LEAD_CAPTURE);
  const leadCaptureEnabled =
    variant === variations[features.ENABLE_LEAD_CAPTURE].ON;

  return (
    <Paper
      data-test-id="ThankYouPartnerSignUp"
      classes={{ root: clsx(classes.banner, classes.bannerDark) }}
    >
      <div>
        <Typography variant="h3" component="h3" className={classes.title}>
          Earn up to <span className={classes.highlight}>£250</span> for each
          customer you recommend
        </Typography>
        <Typography>
          Complete a short form to become a Partner in {partnerName}’s team and
          we’ll give you all you need to get started.
        </Typography>
        <div ref={ref} className={classes.actions}>
          <Button
            onClick={handleSignup}
            variant="contained"
            size="large"
            href={`/partner/join/customer/${registrationToken}`}
            target="_blank"
            rel="noopener noreferrer"
            disabled={!registrationToken}
            loading={registrationTokenLoading}
            fullWidth={!isDesktop}
          >
            Become a UW Partner
          </Button>
        </div>
      </div>
      {!leadCaptureEnabled && (
        <div className={classes.graphicWrapper}>
          <picture className={classes.graphic}>
            <source
              media="(min-resolution: 150dpi)"
              srcSet="images/thankyou/tyPartnerSignup2x.png"
            />
            <img src="images/thankyou/tyPartnerSignup.png" alt="" />
          </picture>
        </div>
      )}
    </Paper>
  );
};

PartnerSignUp.propTypes = {
  partnerName: PropTypes.string,
  registrationToken: PropTypes.string,
  registrationTokenLoading: PropTypes.bool.isRequired,
  fetchRegistrationToken: PropTypes.func.isRequired,
};

export default PartnerSignUp;
