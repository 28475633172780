import { connect } from 'react-redux';
import Renewals from './Renewals';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { setLeadCaptureDialog } from 'app/redux/modules/LeadCapture/actions';

const mapStateToProps = (state) => ({
  products: OrderSummaryApi.getAggregatedTotals(state),
});

export default connect(mapStateToProps, {
  setLeadCaptureDialog,
})(Renewals);
