import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { sanitiseText } from 'redux/utils/sanitise';
import { getLogger, level } from 'app/lib/logger';
import styles from './styles';

const logger = getLogger('[Deprecated]', level.debug, console);

const TextField = ({
  id = null,
  tabIndex = 0,
  autoFocus,
  dataTestId,
  dataPayment,
  onChange,
  onBlur,
  value,
  label,
  type = 'text',
  fullWidth = true,
  placeholder,
  error,
  errorMessage,
  margin,
  required,
  textFieldClasses = {},
  classes,
  ...others
}) => {
  //FIXME: all refactored components should use `NewTextField`
  logger.debug(
    `The 'TextField' component is deprecated and will be removed. Use '/Shared/newTextField' instead`
  );
  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      margin={margin || 'normal'}
      required={required || false}
      classes={{ root: textFieldClasses.form }}
    >
      <MuiTextField
        label={label}
        id={id || dataTestId}
        data-test-id={dataTestId}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={(event) => onChange(event, sanitiseText(event.target.value))}
        autoFocus={autoFocus}
        onBlur={onBlur}
        classes={{
          root: classnames(classes.input, textFieldClasses.input),
        }}
        inputProps={{
          tabIndex: tabIndex || null,
          'data-payment': dataPayment || null,
          'data-test-id': dataTestId,
        }}
        variant="filled"
        {...others}
      />
      {error && (
        <FormHelperText
          id={id || dataTestId}
          classes={{ root: textFieldClasses.helper }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  dataTestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataPayment: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  required: PropTypes.bool,
  textFieldClasses: PropTypes.shape({
    input: PropTypes.string,
    label: PropTypes.sring,
    helper: PropTypes.string,
    form: PropTypes.string,
  }),
  classes: PropTypes.object,
};

export default withStyles(styles)(TextField);
