export default {
  container: {
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
  },
  player: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
};
