export default (theme) => ({
  base: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(3),
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    flex: '1 1 auto',
    color: theme.palette.common.white,
  },
  titleCenter: {
    textAlign: 'center',
  },
  iconLeft: {
    height: 24,
    width: 24,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  clickable: {
    '&:hover': {
      opacity: '0.9',
      cursor: 'pointer',
    },
  },
});
